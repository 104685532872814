:root {
  font-size: min(1.4vh, 12px);
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}
