// .app-container {
//   height: 100%;
//   nav {
//     backdrop-filter: saturate(0.5);
//   }
// }
.chat-window {
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .header {
    color: #656464;
  }
  hr {
    border: 1.5px solid;
    color: #d9d9d9;
  }
}

.chat-window__messages {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-bottom: 10rem;

  li {
    margin: 1rem;
    padding: 0.5rem;
    background-color: #f1f0f0;
    border-radius: 10px;
    white-space: pre-wrap;
    line-height: 30px;
    display: flex;

    &:nth-child(odd) {
      background-color: rgb(235, 255, 235);
    }

    i {
      line-height: 30px;
      font-size: 30px;
    }
  }
}
.chat-window__wrapper-form {
  position: fixed;
  bottom: 1rem;
  width: 80%;
  padding: 1rem 0rem 1rem;
  margin-top: 1rem;
  left: 50%;
  transform: translate(-50%);
}
.chat-window__input-form {
  display: flex;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.chat-window__input-form__input {
  flex-grow: 1;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;

  @media (max-width: 768px) {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.chat-window__input-form__submit-button {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: none;
  // background-color: #3a3c3f;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  // color: white;
  font-size: 1.1rem;
  cursor: pointer;

  &:hover {
    background-color: #515559;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
}
.avatar {
  width: 33px;
  height: 35px;
  padding: 1px;
}
.answer-title {
  color: #333333;
}
.textarea-wrapper {
  position: relative;
}

.textarea-wrapper .form-control {
  border-radius: 3rem;
  background: #f1efef;
  resize: none;
  overflow-y: hidden;
  border: none;
  padding-right: 4rem !important;
  max-height: 12rem;
  ::placeholder {
    color: #afafaf;
  }
  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: #d1deff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #d1deff;
  }
}

.textarea-wrapper .svg-button {
  right: 2rem;
  font-size: 2.5rem;
  color: #656464;
}
.form-check-input:checked {
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.form-check-label {
  color: #6c757d;
}
.checked {
  .form-check-label {
    color: #333;
  }
}
.privacy {
  font-size: 0.8rem;
}
.loading-text {
  color: #8daeff;
}
.h-0 {
  height: 0px !important;
}
.resources {
  transition: all 0.2s linear;
  height: 19rem;
}
.wmo-no,
.select-group {
  width: 20rem !important;
}

#top-k {
  width: 5rem !important;
}
#gpt-model {
  width: 12rem !important;
}

@media (max-width: 575.98px) {
  .textarea-wrapper {
    padding-block: 0.7rem;
  }
  .resources {
    height: auto;
  }
  .chat-window__body {
    overflow-y: visible !important;
  }
  .chat-window__footer {
    margin-inline: 0 !important;
  }
  .mobile-clear {
    height: 12rem;
  }
  .text-privacy {
    position: fixed !important;
    bottom: 0rem;
    background: #fff;
    box-shadow: 0px -4px 20px;
  }
}
@media (min-width: 575.98px) {
  .chat-window__body {
    overflow-y: hidden !important;
  }
  .answers-wrapper {
    overflow-y: auto;
  }
}
.k-input *,
.k-input *::before,
.k-input *::after,
.k-picker *,
.k-picker *::before,
.k-picker *::after,
.k-list-item,
.k-list-header-text,
.k-list-group-item {
  font-size: 1.3rem !important;
}

.k-input-solid:focus,
.k-input-solid.k-focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  background-color: #e0e9ff;
  color: #333;
}
.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: none;
}
.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
  color: #333;
  background-color: #d1deff;
}
.k-input-solid {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
.k-input-inner::placeholder {
  color: #6d757c;
}
