.layout {
  --brand-size: 6vh;
  .brand {
    cursor: pointer;
    height: var(--brand-size, 5rem);
    @media (max-width: 576px) {
      position: absolute;
      top: 0.5rem;
      right: 0;
    }
  }
  .page-container {
    height: 100%;
    @media (max-width: 576px) {
      height: calc(100% - 5.5rem);
    }
  }
  .pages {
    height: calc(100% - var(--brand-size, 5rem));
    @media (max-width: 576px) {
      height: calc(100% - 5.5rem);
    }
  }
}
