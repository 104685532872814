@import url("../node_modules/bootstrap/scss/bootstrap.scss");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

* {
  box-sizing: border-box;
}
#root,
body,
html {
  height: 100%;
  background: #ffffff;
}

/* For WebKit-based browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Adjust scrollbar width */
  height: 6px; /* Adjust scrollbar height for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track background */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #888; /* Thumb color on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #b0b0b0 #f0f0f0; /* Thumb color and track color */
}

/* For IE and Edge Legacy (optional fallback) */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Minimal scrollbar for older Edge */
}
