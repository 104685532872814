.menu {
  padding-inline: 2rem;
  color: #656464;
  // font-size: 2.5rem;
  transition: all 0.1s linear;
  background-color: #e0e9ff;
  width: 7rem;

  .new-chat {
    background-color: #f1efef;
    color: #656464 !important;
    font-weight: 500;
    border-radius: 3rem;
    height: 3rem;
    margin-left: -0.5rem;
    padding: 0.5rem;
  }

  @media (max-width: 576px) {
    width: 100% !important;
    text-align: center;
    align-items: center;
    justify-content: center;
    ul {
      font-size: 2rem;
    }
    &__links {
      width: 100% !important;
      justify-content: space-around;
      margin: auto;
    }
  }

  li {
    &.active {
      a {
        color: #333 !important;
      }
    }
  }

  a {
    color: #656464;
    span {
      // font-size: 1.5rem;
      line-height: 2.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &.open {
    width: 18rem;
    .new-chat {
      width: 12rem;
    }
  }
}
