.samples {
  &__header {
    color: #656464;
  }
  &__container {
    grid-template-columns: auto auto;
  }
  .slider__list:hover .slider__arrow {
    display: none !important;
  }
  .slider-wrapper {
    align-items: center;
    .slider-item {
      height: 100%;
      .btn {
        height: 100%;
      }
    }
  }
  .btn {
    border: none;
    background: #f1efef;
    min-height: 5rem;
    &:hover {
      background: #d1deff;
      color: #333333;
    }
    i {
      right: 1rem;
      bottom: 1rem;
    }
  }
}
