.database {
  .table-dark {
    // --bs-table-bg: #767b87 !important;
    // --bs-table-striped-bg: #595d65 !important;
    --bs-table-bg: #5d6372 !important;
    --bs-table-striped-bg: #484c55 !important;
    a {
      color: #61cbfb;
    }
  }
  .more-db {
    p {
      line-height: 1rem;
      padding-bottom: 0;
      margin-bottom: 0.5rem;
    }
  }
  #accordion {
    overflow-y: auto;
    box-sizing: content-box;
  }
}
